import React from 'react';
import './eheat.css';
import eheat from '../../assets/eheat.png';
import eheatbg from '../../assets/eheatbg.png';


const Eheat = () => {
         return (
         
        <div className="eheat-transformer-tank-container">
          <div className="eheat-content">
            <div className="eheat-tank-image">
              <img src={eheat} alt="eheat- Transformer Tank" />
            </div>
            <div className="eheat-overlay-text">
              <h1>Electric Heating Chambers</h1>
              <p>
              We are also engaged in manufacturing Electric Heating Chambers and expertise in offering service that our customers have never experienced. Our forwarded heating chambers are appropriate for adjusting temperature, the thermostat of these machineries provides more security and with a protection device. These chambers are designed and developed as per the standards set-up by ISO with the use of advanced technology. Our forwarded products are very demanding across globe. Furthermore, our products are widely appreciated among clients for preventing shock hazard for easy testing features.
              </p>
            </div>
          </div>
        </div>
        
      );
    }
    
    export default Eheat;
    