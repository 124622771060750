import React from 'react';
import { AiFillLinkedin } from 'react-icons/ai';
import './footer.css';
import logoo from '../../assets/logoo.jpg'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about-us">
          <img src={logoo} alt="Logo" className="footer-logo" /> 
          <h4><a href='/aboutus'>About Us</a></h4>
          <ul>
            <li>Overview</li>
            <li>Vision & Mission</li>
            <li><a href='https://www.linkedin.com/company/apparna-fab-tech-pvt-ltd/'><AiFillLinkedin size="35"></AiFillLinkedin></a></li>
          </ul>
        </div>

        <div className="footer-section services">
          <h4><a href='/allservices'>Services</a></h4>
          <ul>
            <li>Transformer Tank</li>
            <li>Transformer Core Frame parts</li>
            <li>Coil Winding Machine</li>
            <li>Electric Heating Chamber</li>
            <li>Transformer Pitch Plates</li>
          </ul>
        </div>

        <div className="footer-section blog">
          <h4><a href='/blog'>Blog</a></h4>
          <ul>
            <li>Quality</li>
            <li>Gallery</li>
            <li>Certifications</li>
          </ul>
        </div>

        <div className="footer-section contact-us">
          <h4><a href='/contactus'>Contact Us</a></h4>
          <p>Managing Director</p>
          <p>Tel: +91 9948298693</p>
          <p>Email: info@apparnafabtech.net</p>
          <button><a href='https://forms.gle/mWUZqgUrtNRCFFqy6' className="contact-button">Send EMAIL/SMS</a></button>
        </div>

        <div className="footer-section address">
          <h4><a href='https://maps.app.goo.gl/FsNGgP92WwKL85EBA'>Address</a></h4>
          <p>Apparna Fab-Tech Pvt Ltd, PLOT NO: 44,
          TIF MSME GREEN INDUSTRIAL PARK TSIIC,
          DANDUMALKAPUR, CHOUTUPPAL MANDAL
          Yadadri-Bhongiri District-500051, Telangana, India.</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>©Copyright 2024 APPARNA FAB-TECH PVT LTD. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
