import React from 'react';
import './certifications.css';
import { Link } from 'react-router-dom';
import nsicbg from '../../assets/nsicbg.png'; 
import certbg from '../../assets/certbg.png';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Certifications = () => {
  return (
    <><Navbar/>
    <div className="certifications-section">
      <div className="cert-header">
        <h2>Certifications</h2>
      </div>
      <div className="certifications-container">
        <div className="cert-item">
          <img src={nsicbg} alt="Certification 1" className="cert-image" />
        </div>
        <div className="cert-item">
          <img src={certbg} alt="Certification 2" className="cert-image" />
        </div>
      </div>
    </div>
    <Footer/></>
  );
};

export default Certifications;
