import React, { useEffect } from 'react';
import './clients1.css';
import indotech from "../../assets/indotech.png"
import telawne from "../../assets/telawne.png"
import krk from "../../assets/krk.png"
import kamath from "../../assets/kamath.png"
import klr from "../../assets/klr.png"
import kpel from '../../assets/kpel.png'
import hammond from '../../assets/hammond.png'
import kkrao from '../../assets/kkrao.png'
import ece from '../../assets/ece.png'
import abirami from '../../assets/abirami.jpg'
import sudhir from '../../assets/sudhir.jpg'
import sps from '../../assets/sps.jpg'
import andrew from '../../assets/andrew.jpg'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';


const Clients1 = () => {
  
    useEffect(() => {
      
        const scrollElements = document.querySelectorAll('.scroll-animation');
    
        const elementInView = (el, dividend = 1) => {
          const elementTop = el.getBoundingClientRect().top;
          return (
            elementTop <= (window.innerHeight || document.documentElement.clientHeight) / dividend
          );
        };
    
        const elementOutOfView = (el) => {
          const elementTop = el.getBoundingClientRect().top;
          return (
            elementTop > (window.innerHeight || document.documentElement.clientHeight)
          );
        };
    
        const displayScrollElement = (element) => {
          element.classList.add('visible');
          element.classList.remove('not-visible');
        };
    
        const hideScrollElement = (element) => {
          element.classList.remove('visible');
          element.classList.add('not-visible');
        };
    
        const handleScrollAnimation = () => {
          scrollElements.forEach((el) => {
            if (elementInView(el, 1.25)) {
              displayScrollElement(el);
            } else if (elementOutOfView(el)) {
              hideScrollElement(el);
            }
          });
        };
    
        window.addEventListener('scroll', handleScrollAnimation);
    
        // Run the animation on load as well
        handleScrollAnimation();
    
        return () => {
          window.removeEventListener('scroll', handleScrollAnimation);
        };
      }, []);

    
    
      return (
        <><Navbar/>
        <div className="clients2-section">
          <div className="clients2-header">
            <h2>Our Clients</h2>
          </div>
          <div className="clients2-logos">
          <div className="logo2-item">
              <img src={indotech} alt="Indo" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={hammond} alt="Hammond Power Solutions" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={kkrao} alt="KKRAO Group" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={ece} alt="ECE Industries Limited" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={andrew} alt="Yule" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={klr} alt="klr" className="scroll-animation" />
            </div>            
            <div className="logo2-item">
              <img src={telawne} alt="telaw" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={krk} alt="Krk" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={kamath} alt="kamath" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={kpel} alt="kpel" className="scroll-animation" />
              </div>
              <div className="logo2-item">
              <img src={abirami} alt="Abirami" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={sudhir} alt="Sudhir" className="scroll-animation" />
            </div>
            <div className="logo2-item">
              <img src={sps} alt="SPS Transformers P. Ltd" className="scroll-animation" />
            
            </div>
          </div>
        </div>
        <Footer/></>
      );
    };
    
    
    export default Clients1;
    