import React from 'react'
import './app.css'
import Navbar from './components/Navbar/Navbar'
import Main from './components/Main/Main'
import Home from './components/Home/Home'
import AboutUs from './components/AboutUs/AboutUs'
import Footer from './components/Footer/Footer'
import Gallery2 from './components/Gallery2/Gallery2'
import Clients2 from './components/Clients2/Clients2'
import Abdup from './components/Abdup/Abdup'


const App = () => {
  return (
    <>
    <Navbar/>
    <Home/> 
    <Abdup/>   
    <Main/>
   <Gallery2/>
   <Clients2/>
    <Footer/>
    

    </>
    
  )
}

export default App;