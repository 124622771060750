import React from 'react';
import './transformertank.css';
import { Link } from 'react-router-dom';
import trans from '../../assets/trans.png';
import transbg from '../../assets/transbg.png'

const TransformerTank = () => {
         return (
        <div className="transformer-tank-container">
          <div className="content">
            <div className="tank-image">
              <img src={trans} alt="Transformer Tank" />
            </div>
            <div className="overlay-text">
              <h1>M S Transformer Tank</h1>
              <p>
                We are one of the notable manufacturers and suppliers of high utility collection of Transform Tanks, Power Transformer Tanks, and Transformer Tanks. The products are valued for their operational fluency, durability, longer service life, and robust construction. Our range has been manufactured from high grade raw material with the help of latest technology. Apart from this, we offer this outstanding array at a very reasonable cost.
              </p>
            </div>
          </div>
        </div>
      );
    }
    
    export default TransformerTank;
    