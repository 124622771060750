import React from 'react';
import './cwind.css';
import cwind from '../../assets/cwind.png';
import cwindbg from '../../assets/cwindbg.png'


const Cwind = () => {
         return (
          
        <div className="cwind-transformer-tank-container">
          <div className="cwind-content">
            <div className="cwind-tank-image">
              <img src={cwind} alt="cwind-Transformer Tank" />
            </div>
            <div className="cwind-overlay-text">
              <h1>Transformer Coil Winding Machines</h1>
              <p>
              We offer a wide range of precision-engineered Coil Winding Machines that is highly resistant to corrosion. Made from quality raw materials, these machines are extremely durable and can withstand long hours of use without any breakdowns. We bring forth extensive quality  Transformer Coil Winding Machines that can be useful for clients in technical applications. Alloyed from quality ore elements, these machines are formed by using superior quality materials. We are a persuasive name in manufacturing and supplying of H.V Coil Winding Machines Up To 100 MVA that are manufactured of using advanced quality material and latest technology.
            </p></div>
          </div>
        </div>
       
      );
    }
    
    export default Cwind;
    