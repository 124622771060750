import React from 'react';
import './tpitch.css';
import tpitch from '../../assets/tpitch.png';
import tpitchbg from '../../assets/tpitchbg.png'


const Tpitch = () => {
         return (
         
        <div className="tpitch-transformer-tank-container">
          <div className="tpitch-content">
            <div className="tpitch-tank-image">
              <img src={tpitch} alt="tpitch-Transformer Tank" />
            </div>
            <div className="tpitch-overlay-text">
              <h1>Transformer Pitch Plates</h1>
              <p>
              Matching to the huge market demands, we are involved in offering best quality of Transformer Pitch Plates. These Transformer Pitch Plates are available in various specifications and dimensions in the market. Transformer Pitch Plates are extensively used by and applauded by clients for their high performance core frames.
              </p>
            </div>
          </div>
        </div>
        
      );
    }
    
    export default Tpitch;
    