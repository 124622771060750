import React from 'react';
import './main.css';
import coil from '../../assets/coil.png'
import electric from '../../assets/electric.png'
import pitch from '../../assets/pitch.png'
import transformertank from '../../assets/transformertank.jpeg'

const Serv = () => {
  return (
<div className="servicesmain">
      <h1>Our Services</h1>
      <p>Apparna set the trend with manufacturing processes, delivering superior quality and reliability.</p>

  
    <div className="services-container">
      <div className="service-item">
        <img src={transformertank} alt="Transformer Tanks" />
        <div className="service-info">
          <h3>Transformer Tanks</h3>
          <button><a href="/allservices" className="view-more">View More →</a></button>
        </div>
      </div>
      <div className="service-item">
        <img src={coil} alt="Coil Winding Machine" />
        <div className="service-info">
          <h3>Coil Winding Machine</h3>
          <button><a href="/allservices" className="view-more">View More →</a></button>
        </div>
      </div>
      <div className="service-item">
        <img src={electric} alt="Electric Heating Chambers" />
        <div className="service-info">
          <h3>Electric Heating Chambers</h3>
          <button><a href="/allservices" className="view-more">View More →</a></button>
        </div>
      </div>
      <div className="service-item">
        <img src={pitch} alt="Transformer Pitch Plates" />
        <div className="service-info">
          <h3>Transformer Pitch Plates</h3>
          <button><a href="/allservices" className="view-more">View More →</a></button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Serv;
