import React from 'react';
import './quality.css';
import qualitybg from '../../assets/qualitybg.png'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Quality = () => {
  return (
    <><Navbar/>
    <div className="quality-container">
      
      <div className="quality-overlay">
        <div className="quality-text">
        
        <h2>Our Quality Assurance</h2>
        <p>
          We have hired a panel of expert quality controllers who keep stringent
          watch during the different stages of production of our array of
          industrial products. Our quality tested products collection is valued
          for its longer functional life and corrosion resistant structure along
          with high quality packaging.
        </p>
        <h2>Quality, Environment, Occupational Health & Safety Policy</h2>
        <p>
          We commit to offer products & services required by power and
          industrial sector by continually improving our management system to
          meet the stakeholders expectations.
        </p>
        <h2>Client Satisfaction</h2>
        <p>
          This client-centric organization emphasizes fulfilling customer
          satisfaction by employing experienced professionals who closely
          collaborate with clients to understand their needs.
        </p>
      </div></div>
    
        <img src={qualitybg}/>
      </div>
    <Footer/></>
  );
};

export default Quality;
