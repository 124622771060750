import React from 'react';
import './asloc.css'; // Import the CSS file
import asloc from '../../assets/asloc.png'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';


const Location = () => {
  return (
    <><Navbar/>
    <div className="location-container">
      
      <div className="location-overlay">
        <div className="location-text">
        <h1>Location</h1>
      <p>
        TSIIC INDUSTRIAL PARK, PLOT NO.2/A, PHASE-III, STREET, Mahalaxmi Nagar, IOCL,
        Cherlapalli, Hyderabad, Secunderabad, Telangana NO.11
      </p>
      
  <button><a href='https://maps.app.goo.gl/4QG6SAMD9owNgAf79'>Get Location</a>
</button>
    </div></div>
      <img src={asloc} alt="Location Map" />
      
      
      
  </div>

    
  <Footer/></>
  );
};

export default Location;
