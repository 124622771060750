import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import Blog from './components/Blog/Blog';
import AboutUs from './components/AboutUs/AboutUs';
import Main from './components/Main/Main';
import Fabrications from './components/Fabrications/Fabrications';
import Footer from './components/Footer/Footer';
import HomePage from './components/Home/Home';
import Careers from './components/Careers/Careers';
import Associatesub from './components/Associatesub/Associatesub';
import Mission from './components/Mission/Mission';
import TransformerTank from './components/TransformerTank/TransformerTank';
import DTT from './components/DTT/DTT';
import TCFP from './components/TCFP/Tcore';
import EHC from './components/EHC/Eheat';
import TCWM from './components/TCWM/Cwind';
import TPitch from './components/TPitch/Tpitch';
import Quality from './components/Quality/Quality';
import Gallery from './components/Gallery/Gallery';
import Clients2 from './components/Clients2/Clients2';
import Certifications from './components/Certifications/Certifications';
import Asloc from './components/Asloc/Asloc';
import Just from './components/Just/Just';
import ContactUs from './components/ContactUs/ContactUs';
import Clients1 from './components/Clients1/Clients1';
import Gallery2 from './components/Gallery2/Gallery2';
import Abdup from './components/Abdup/Abdup';
import Explore from './components/Explore/Explore';

const router= createBrowserRouter([
    {
        path: "/", element:<App/>},
        {
            path: "blog", element:<Blog/>},
        {
                path: "/", element:<HomePage/>},
                {
                    path: "aboutus", element:<AboutUs/>},
                    {
                        path: "associatesub", element:<Associatesub/>},
                    {
                        path: "services", element:<Main/>},
                        {
                            path: "fabrications", element:<Fabrications/>},
                            {
                                path: "careers", element:<Careers/>},
                            {
                                path: "footer", element:<Footer/>},
                                {
                                    path: "mission", element:<Mission/>},
                                    {
                                        path: "transformertank", element:<TransformerTank/>},
                                        {
                                            path: "dtt", element:<DTT/>},
                                            {
                                                path: "tcore", element:<TCFP/>},
                                                {
                                                    path: "cwind", element:<TCWM/>},
                                                    {
                                                        path: "eheat", element:<EHC/>},
                                                        {
                                                            path: "tpitch", element:<TPitch/>},
                                                            {
                                                                path: "quality", element:<Quality/>},
                                                                {
                                                                    path: "gallery", element:<Gallery/>},
                                                                    {
                                                                        path: "clients2", element:<Clients2/>},
                                                                        {
   path: "certifications", element:<Certifications/>},                  
   {
    path: "asloc", element:<Asloc/>}, 
    {
        path: "allservices", element:<Just/>},  
        {
            path: "contactus", element:<ContactUs/>},       
            {
                path: "clients1", element:<Clients1/>},
                {
                    path: "gallery2", element:<Gallery2/>},    
                    {
                        path: "abdup", element:<Abdup/>},
                        {
                            path: "explore", element:<Explore/>},                                         
                                                                                             
                                                    
        
                                       
    
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);



