import React from 'react';
import './associatesub.css';
import { Link } from 'react-router-dom';
import asso from '../../assets/asso.png'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';


const Associatesub = () => {
  return (
  <><Navbar/>
    <div className="sub-container">
      <header className="sub-header">
        <h1 className="sub-title">ASSOCIATE</h1>
      </header>
      <div className="sub-main-content">
        <img src={asso} alt="Engineering Works" className="sub-background-image" />
        <div className="sub-overlay">
          <h2 className="sub-welcome-text">Welcome to</h2>
          <h2 className="sub-company-name">Apparna Engineering Works</h2>
        </div>
      </div>
      <footer className="sub-footer">
        <div><button><a href="/services" className="circle">Our Products</a></button></div>
        <div><button><a href="/certifications" className="circle">Achievements</a></button></div>
        <div><button><a href="/asloc" className="circle">Location</a></button></div>
      </footer>
    </div>
    <Footer/></>
  );
}


export default Associatesub;
