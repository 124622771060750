import React from 'react';
import './careers.css';
import Ellipsee from '../../assets/Ellipsee.jpeg'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Careers = () => {
    return (
        <><Navbar/>
        <div className="careers-container">
            <div className="text-container">
                <h1 className="careers-title">Careers</h1>
                <p className="careers-subtitle">
                    Let’s grow together!<br />Join Us
                </p>
                <a href="/explore" className="careers-button">Explore all openings</a>
                
            </div>
            <div className="image-container">
                <img src={Ellipsee} alt="Handshake" className="careers-image" />
            </div>
        </div>
        <Footer/></>
    );
};

export default Careers;
