import React from 'react';
import './mission.css'; // Link to the CSS file
import { Link } from 'react-router-dom';
import missionbg from '../../assets/missionbg.png'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Mission = () => {
  return (
    <><Navbar/>
    <div className="mission-legacy-container">
      <div className="mission-image-section">
        <img src={missionbg} alt="Mission and Vision" className="mission-image" />
      </div>
      <div className="mission-text-section">
        <h2>Our Mission and Vision:</h2>
        <p>
          Our mission is to continue delivering exceptional value to our clients through innovative
          solutions, superior quality products, and unwavering service. We envision setting new
          benchmarks in the transformer tank fabrication sector, leading with sustainability, efficiency,
          and technological advancement.
        </p>

        <h2>Our Legacy:</h2>
        <p>
          For over 29 years, Apparna Engineering Works has been at the forefront of the transformer tank
          fabrication industry, delivering top-notch services and products that exceed client
          expectations. Our legacy is built on the pillars of trust, craftsmanship, and enduring
          relationships with our clients and communities.
        </p>
      </div>
    </div>
    <Footer/></>
  );
};

export default Mission;
