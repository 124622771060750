import React from 'react';
import './explore.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Explore = () => {
  const jobs = [
    {
      title: 'Quality Intern/Quality Engineer',
      department: 'Manufacturing',
      posted: 'Posted on 27th september 2024',
      description:
        'We are seeking a detail-oriented Quality Engineer to join our team. The ideal candidate will be responsible for ensuring that our products meet the highest quality standards through rigorous testing, analysis, and continuous improvement processes. This role requires a strong understanding of quality control methodologies and a commitment to enhancing product quality and reliability',
      detailsFile:'/files/Quality engineer Description.docx'
    },
    {
      title: 'Design intern/Design Engineer',
      department: 'Manufacturing',
      posted: 'Posted on 27th september 2024',
      detailsFile:'/files/Design Engineer job description.docx',
      description:'We are looking for a creative and detail-oriented Design Engineer to join our team. The ideal candidate will be responsible for designing, developing, and improving products and systems to meet customer and regulatory requirements. This role involves collaboration with cross-functional teams and requires a strong understanding of engineering principles and design software',
    },
    {
      title: 'Welder',
      department: 'Production' ,
      location: 'Choutuppal and Cherlapally',
      posted: 'Posted on 27th september 2024',
      description: 'The Welder is responsible for joining metal parts using various welding techniques and equipment. This role involves interpreting blueprints and technical drawings, preparing metal surfaces, and ensuring high-quality welds that meet specifications and safety standards. The Welder will work on a variety of projects, from small components to large structures, and will be expected to maintain a clean and safe work environment.',
      detailsFile:'/files/WELDER JOB DESCRIPTION.pdf'
    },
    {
        title: 'Production Supervisor',
        department: 'Production' ,
        location: 'Choutuppal and Cherlapally',
        posted: 'Posted on 27th september 2024',
        detailsFile:'/files/Production supervisor Description.docx',
        description: 'The Production Supervisor is responsible for overseeing and managing the production line, ensuring that all manufacturing processes are carried out efficiently, safely, and in accordance with company standards. This role involves supervising production staff, coordinating activities, and ensuring quality control throughout the production process',
    },
    {
        title: 'Turner',
        department: 'Manufacturing' ,
        location: 'Choutuppal and Cherlapally',
        posted: 'Posted on 27th september 2024',
        description: 'The Turner is responsible for operating and maintaining turning machines to create precise cylindrical components and parts. This role involves interpreting technical drawings, setting up machines, and ensuring that parts meet quality and dimensional specifications. The Turner will work with various materials and must be skilled in using manual and CNC lathes.',
        detailsFile:'/files/tuner-des.pdf'
    },
    {
      title: 'Fitter',
      department: 'Production' ,
      location: 'Choutuppal and Cherlapally',
      posted: 'Posted on 27th september 2024',
      description: 'The Fitter is responsible for the assembly, installation, and maintenance of machinery and mechanical systems. This role involves interpreting technical drawings, measuring and cutting materials, and ensuring that all fittings are securely and accurately installed. The Fitter will work on a range of projects and equipment, requiring a strong understanding of mechanical systems and attention to detail to ensure operational efficiency and safety.',
      detailsFile:'/files/FITTER JOB DESCRIPTION.pdf'
  },
      {
      title: 'Production Engineer',
      department: 'Production' ,
      location: 'Choutuppal and Cherlapally',
      posted: 'Posted on 27th september 2024',
      description :'We are seeking a highly motivated and detail-oriented Production Engineer to join our team. In this role, you will be responsible for optimizing production processes, providing technical support to production teams, and contributing to continuous improvement initiatives. The ideal candidate will have a strong background in engineering, project management, and data analysis, with a focus on enhancing efficiency, reducing waste, and maintaining product quality.',
      detailsFile:'/files/production engineer.docx'
      }
];

  return (
    <><Navbar/>
    <div className="job-postings">
      {jobs.map((job, index) => (
        <div className="job-card" key={index}>
          <div className="job-header">
            <h3>{job.title}</h3>
            <span>{job.department}</span>
            <span>{job.location}</span>
          </div>
          <div className="job-meta">
            <span>{job.posted}</span>
          </div>
          <p className="job-description">{job.description}</p>
          <a href={job.detailsFile} className="details-button" target="_blank" rel="noopener noreferrer">
            View Salary & Details
          </a>
          <a href='https://forms.gle/u3pqBaGdcz11FWpE9' className="details-button">Apply</a>
        </div>
      ))}
    </div>
    <Footer/></>
  );
};

export default Explore;
