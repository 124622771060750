import React from 'react';
import './dtt.css';
import dtt from '../../assets/dtt.png';
import dttbg from '../../assets/dttbg.png';


const Dtransformer = () => {
         return (
         
        <div className="dtransformer-tank-container">
          <div className="dcontent">
            <div className="dtank-image">
              <img src={dtt} alt="DTransformer Tank" />
            </div>
            <div className="overlay-dtext">
              <h1>Distribution Transformer Tank</h1>
              <p>
              We are actively engaged in manufacturing Transformer Tanks (Power & Distribution Upto 100 MVA) that are authentic in quality. These tanks are broadly appreciated for their rigid quality and robust construction. These tanks are available at very competitive prices, plated with a combination of structural steel, mild steel and stainless steel plates, these tanks are vastly appreciated among clients. Designed and fabricated with different electricity boards for indigenous and export supplies.
              </p>
            </div>
          </div>
        </div>
        
      );
    }
    
    export default Dtransformer;
    