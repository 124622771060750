import React from 'react';
import './tcore.css';
import tcore from '../../assets/tcore.png';
import tcorebg from '../../assets/tcorebg.png';


const Tcore = () => {
         return (
         
        <div className="tcore-transformer-tank-container">
          <div className="tcore-content">
            <div className="tcore-tank-image">
              <img src={tcore} alt="tcore-Transformer Tank" />
            </div>
            <div className="tcore-overlay-text">
              <h1>Transformer Core Frame Parts</h1>
              <p>
              We are offering an exciting range of Transformer Core Frame Parts (25 MVA) at affordable prices. This assortment is exact dimension and efficiently designed to perform at high speed. Using modern technology and anti-abrasive spares, our machine is designed in such a way, to carve niche in market. Fabricated using high-grade metallic alloys, this range of parts are corrosion and abrasion resistant. We ensure the quality remain intact and won’t lose its quality and our products are widely acclaimed for their:
Precise engineering
Robust construction
Easy installation
Longer functional life
              </p>
            </div>
          </div>
        </div>
        
      );
    }
    
    export default Tcore;
    