import React from 'react';
import './home.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import dpp from '../../assets/dpp.png'


const HomePage = () => {
  return (
  

    <div className="home-banner">
      <div className="overlay">
        <h2>We envision Manufacturing Excellence driven by Innovation, Quality, and Customer Satisfaction</h2>
        <button>
        <a href='/aboutus' className="learn-more-btn">Learn More</a>
        </button>
      </div>
    </div>
    
  
  );
};

export default HomePage;

