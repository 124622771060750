import React from 'react';
import './fabrications.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import pikso from '../../assets/pikso.jpeg'

function Fabb() {
  return (
    <><Navbar/>
    <div className="Apppp">
      <div className="containerrr">
        <div className="titleee">Fabrication Process</div>
        <div className="process-list">
          <div className="process-item">Cutting</div>
          <div className="process-item">Processing</div>
          <div className="process-item">Fabrications</div>
          <div className="process-item">Machining</div>
          <div className="process-item">Testing</div>
          <div className="process-item">Blasting</div>
          <div className="process-item">Painting</div>
          <div className="process-item">Assembling</div>
          <div className="process-item">Packing</div>
        </div>
      </div>
    </div>
    <Footer/></>
  );
}

export default Fabb;