import React from 'react';
import Navbar from '../Navbar/Navbar';
import './blog.css'; 
import blogg from '../../assets/blogg.png'
import Footer from '../Footer/Footer';

const Blog = () => {
  return (
  <><Navbar/>
    <div className="blog-page">
      <div className="blog-image">
        <div className="blog-content">
          <h1>BLOG</h1>
          <div className="blog-buttons">
            <a href='/quality' className="blog-button">QUALITY</a>
            <a href='/gallery' className="blog-button">GALLERY</a>
            <a href='/certifications' className="blog-button">CERTIFICATION</a>
          </div>
        </div>
      </div>
    </div>
    <Footer/></>
  
  );
};

export default Blog;
