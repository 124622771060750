import React from 'react';
import './contactus.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';


const ContactUs = () => {
  return (
    <><Navbar/>
    <div className="contact-us-container">
      <div className="contact-us-content">
        <h2>CONTACT US</h2>
        <div className="contact-form">
          <h3>ONLINE INQUIRY</h3>
          <form>
            <p>Please click the below Inquiry button to proceed to Google Form for Information submission</p>
            
            <button type="submit"><a href='https://forms.gle/mWUZqgUrtNRCFFqy6'>Inquiry</a></button>
          </form>
        </div>
        <div className="contact-details">
          <h3>CONTACT DETAILS</h3>
          <p><a href="mailto:info@apparnafabtech.net">Apparna Fab-Tech Pvt Ltd</a></p>
          <p>+91 9948298693</p>
          <div>
            <p><strong>Apparna Fab-tech Pvt Ltd</strong></p>
            <p>PLOT NO: 44,
          TIF MSME GREEN INDUSTRIAL PARK TSIIC,
          Dandumalkapur<br />Choutuppal Mandal
          Yadadri-Bhongiri District-500051, Telangana, India.</p>
          </div>
          <div>
            <p><strong>Apparna Engineering Works</strong></p>
            <p>TSIIC INDUSTRIAL PARK, PLOT NO.2/A, PHASE-III STREET, Mahalaxmi Nagar, IOCL<br />Cherlapalli, Hyderabad, Secunderabad, Telangana, India.</p>
          </div>
          
        </div>
      </div>
    </div>
    <Footer/></>
  );
}

export default ContactUs;
