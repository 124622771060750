import React from "react";
import Navbar from "../Navbar/Navbar";
import TransformerTank from "../TransformerTank/TransformerTank";
import Dtransformer from "../DTT/DTT";
import Cwind from "../TCWM/Cwind";
import Eheat from "../EHC/Eheat";
import Tpitch from "../TPitch/Tpitch";
import Tcore from "../TCFP/Tcore";
import Footer from "../Footer/Footer";

const Just = () => {
    return (
        <><Navbar/>
        <TransformerTank/>
        <Dtransformer/>
        <Tcore/>
        <Cwind/>
        <Eheat/>
        <Tpitch/>
        <Footer/></>
         );
        };
        
        export default Just;
        