import React, { useRef } from "react";
import "./gallery2.css";
import g1 from '../../assets/g1.png';
import g2 from '../../assets/g2.png';
import g3 from '../../assets/g3.png';
import g4 from '../../assets/g4.png';
import g5 from '../../assets/g5.png';


const images = [g1, g2, g3, g4, g5];

const Gallery2 = () => {
  const carouselRef = useRef(null);

  const scrollLeft = () => {
    carouselRef.current.scrollBy({ left: -500, behavior: 'smooth' });
  };

  const scrollRight = () => {
    carouselRef.current.scrollBy({ left: 500, behavior: 'smooth' });
  };

  return (
    
    <div className="gallery-container">
      <h2>Gallery</h2>
      <div className="gallery-controls">
        {/* Left Arrow */}
        <button className="scroll-arrow left" onClick={scrollLeft}>
          &#8249;
        </button>

        {/* Carousel */}
        <div className="carousel" ref={carouselRef}>
          {images.map((image, index) => (
            <div className="card" key={index}>
              <img src={image} alt={`slide-${index}`} />
            </div>
          ))}
        </div>

        {/* Right Arrow */}
        <button className="scroll-arrow right" onClick={scrollRight}>
          &#8250;
        </button>
      </div>
    </div>
    
  );
};

export default Gallery2;
